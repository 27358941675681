export default {
  en: 'en',
  ru: 'ru',
  es: 'es',
  'es-es': 'ES_es',
  'ES_es': 'ES_es',
  de: 'de',
  fr: 'fr',
  nl: 'nl',
  it: 'it',
  'pt-pt': 'pt',
  pt: 'pt',
  'pt-br': 'pt_BR',
  'pt_BR': 'pt_BR',
  pr: 'pr',
  pl: 'pl',
  tr: 'tr',
  vi: 'vi',
  cn: 'zh_CN',
  zh_CN: 'zh_CN',
  'cn-tr': 'zh_TW',
  zh_TW: 'zh_TW'
}
