// const format = (number) => new Intl.NumberFormat().format(number)

const buildDeepLink = ({ deepLink, getParameter }) => {
  getParameter = getParameter ? `&${ getParameter }` : ''

  return deepLink.split('#').join(`${ getParameter }#`)
}

const format = ({ number, numDigit = 2, formatFunction = false }) => {
  if (formatFunction) return '' + formatFunction({ number, numDigit })

  // format from 100000.12345 to 100 000.123
  const formatter = new Intl.NumberFormat('cs', {
    maximumFractionDigits: numDigit,
    style: 'decimal',
    minimumFractionDigits: numDigit
  })

  return formatter.format(number).replace(',', '.')
}

const correctSymbolCases = ({instruments, data}) => {
  // Correct the symbols' letter cases
  data.forEach(i => {
    const current = instruments.find(el => el.code.toLowerCase() === i.code.toLowerCase())

    if (current && current.code !== i.code) current.code = i.code
  })
}

const convertQuote = (quote) => {
  const precisionFactor = Math.pow(10, -(quote.f & 0xF)) // Flags
  return {
    s: quote.s, // Symbol
    b: quote.b * precisionFactor, // Bid
    a: quote.a * precisionFactor, // Ask
    m: quote.m * precisionFactor, // Mid
    r: (quote.r || quote.m) * precisionFactor, // Rate || Mid
    dt: quote.t * 1000, // Time
    n: quote.n, // Number
    iw: (quote.f & 0x10) > 0,
    om: (quote.f & 0x20) > 0
  }
}

const prepareQuotes = (data) => {
  const quotes = []

  if (data.name === 'quotes.update' && data.body && data.body.result && data.body.result.quotes) {
    for (let i = 0; i < data.body.result.quotes.length; i++) {
      quotes.push(convertQuote(data.body.result.quotes[i]))
    }
  }

  return quotes
}

const fillNode = ({node, instrument, callback, formatFunction}) => {
  const tracked = node.dataset.tickerValue
  let fulfilled = false

  if (callback) fulfilled = callback({ node, instrument })

  // if we already handle a case with callback function we do not want to continue filling node
  if (fulfilled) return

  try {
    if (node.dataset.tickerTarget) {
      if (node.dataset.tickerTarget == 'href' && node.tagName !== 'A') node.addEventListener('click', () => window.location.href = instrument[tracked])
      else node[node.dataset.tickerTarget] = instrument[tracked]
    } else if (tracked.toLowerCase() === 'percentchange') {
      if (Number.isNaN(instrument.percentChange) || !Number.isFinite(parseFloat(instrument.percentChange))) return

      node.textContent = getPercentage(instrument.percentChange, formatFunction)
      node.dataset.tickerDirection = getDailyDirection(instrument.percentChange)
    }
    else if (tracked.toLowerCase() === 'dailychange') {
      node.textContent = getDailyChange(instrument.dailyChange, instrument.numDigit, formatFunction)
      node.dataset.tickerDirection = getDailyDirection(instrument.dailyChange)
    }
    else if (tracked === 'dailyTradedTotalVolume') {
      node.textContent = format({number: instrument[tracked], numDigit: 2, formatFunction})
    }
    else if (tracked === 'ask' || tracked === 'bid') {
      node.textContent = format({number: instrument[tracked], numDigit: instrument.numDigit, formatFunction})
      node.dataset.tickerDirection = ''
      void node.offsetWidth
      node.dataset.tickerDirection = instrument[tracked + 'Direction']
    }
    else if (tracked === 'price') {
      node.textContent = format({number: instrument[tracked], numDigit: instrument.numDigit, formatFunction})
      node.dataset.tickerDirection = ''
      void node.offsetWidth
      node.dataset.tickerDirection = instrument.direction
    } else if (tracked) {
      node.textContent = instrument[tracked]
    }
  } catch (e) {
    console.log(e)
    console.error(`field ${tracked} is not supported`)
  }
}

const markUnresolved = ({instruments, symbols}) => {
  instruments.filter(({name, code}) => !name && symbols.includes(code))
      .forEach(instrument => instrument.name = '-')
}

const composeInitialData = ({instruments, data, fillNodeCallback, formatFunction, getParameter}) => data.forEach(i => {
  const instrument = instruments.find(el => el.code === i.code)

  if (instrument) {
    instrument.code = i.code
    instrument.name = i.name
    instrument.price = i.rate
    instrument.direction = ''
    instrument.askDirection = ''
    instrument.bidDirection = ''
    // ask and bid fill be filled with socket
    instrument.ask = 0
    instrument.bid = 0
    instrument.percentChange = i.interval.D1.p.toFixed(2)
    instrument.dailyChange = (i.rate - i.interval.D1.c).toFixed(i.numDigit)
    instrument.dailyTradedTotalVolume = Math.round(i.rate * i.dailyTradedTotalVolume * 100) / 100
    instrument.tradeLink = buildDeepLink({ deepLink: i.tradelink, getParameter })
    instrument.close = i.interval.D1.c // Storing the value, it will be used in further calculations
    instrument.dailyTradedVolume = i.dailyTradedTotalVolume // Storing the value, it will be used in further calculations
    instrument.numDigit = i.numDigit

    instrument.nodes.forEach(node => fillNode({node, instrument, callback: fillNodeCallback, formatFunction}))
  }
})

const updateDigits = ({instruments, data2paste, fillNodeCallback, formatFunction}) => {
  data2paste.forEach(quote => {
    const instrument = instruments.find(i => i.code === quote.code)

    if (!instrument) return

    instrument.nodes.forEach(node => {
      if (node.dataset.tickerTick && node.dataset.tickerTick === 'false') return;
      fillNode({ node, instrument, callback: fillNodeCallback, formatFunction })
    })
  })
}

const getData2paste = (quotes, instruments) => {
  const data2paste = quotes
  .filter(quote => instruments.some(instrument => instrument.code === quote.s))
  .map(quote => {
    const instrument = instruments.find(instrument => instrument.code === quote.s)

    instrument.askDirection = instrument.bid > quote.a ? 'down' : instrument.bid < quote.a ? 'up' : ''
    instrument.bidDirection = instrument.bid > quote.b ? 'down' : instrument.bid < quote.b ? 'up' : ''
    instrument.direction = instrument.price > quote.r ? 'down' : instrument.price < quote.r ? 'up' : ''
    instrument.ask = quote.a
    instrument.bid = quote.b
    instrument.price = quote.r
    instrument.percentChange = (quote.r * 100 / instrument.close - 100).toFixed(2)
    instrument.dailyChange = (quote.r - instrument.close).toFixed(instrument.numDigit)
    instrument.dailyTradedTotalVolume = Math.round(quote.r * instrument.dailyTradedVolume * 100) / 100

    return {
      ask: instrument.ask,
      bid: instrument.bid,
      price: instrument.price,
      direction: instrument.direction,
      percentChange: instrument.percentChange,
      dailyTradedTotalVolume: instrument.dailyTradedTotalVolume,
      code: instrument.code
    }
  })

  return data2paste
}

const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

// const throttle = (func, delay) => {
//   let toThrottle = false;
//   let lastCall = null

//   return function() {
//     if(!toThrottle) {
//       toThrottle = true
//       func.apply(this, arguments)
//       setTimeout(() => {
//         if (lastCall) {
//           lastCall()
//           lastCall = null
//         }
//         toThrottle = false
//       }, delay);
//     }
//     else {
//       lastCall = func.bind(this, ...arguments)
//     }
//   };
// };

const getPercentage = (percentChange, formatFunction) => percentChange > 0 ? `+${format({number: percentChange, formatFunction})}%` : `${format({number: percentChange, formatFunction}).replace('-', '−')}%`
const getDailyChange = (dailyChange, numDigit, formatFunction) => !isFinite(dailyChange) ? '-' : dailyChange > 0 ? `+${format({number: dailyChange, numDigit, formatFunction})}` : `${format({number: dailyChange, numDigit, formatFunction}).replace('-', '−')}`

const getDailyDirection = (dailyChange) => !isFinite(dailyChange) ? "" : dailyChange > 0 ? 'up' : 'down'

export default {
  format,
  correctSymbolCases,
  composeInitialData,
  markUnresolved,
  fillNode,
  convertQuote,
  prepareQuotes,
  getData2paste,
  throttle,
  updateDigits,
  getDailyChange,
  getDailyDirection
}

// Array.prototype.find() polyfill
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      if (this == null) throw new TypeError('"this" is null or not defined')

      var o = Object(this)
      var len = o.length >>> 0

      if (typeof predicate !== 'function') throw new TypeError('predicate must be a function')

      var thisArg = arguments[1]
      var k = 0

      while (k < len) {
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) return kValue
        k++
      }

      return undefined
    },
    configurable: true,
    writable: true
  })
}
