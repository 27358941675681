
import {
  WSGate,
  observeMutations
} from '@web/widgets-utils'

import defaults from './config'
import api from './api'
import utils from './utils'
import i18n from './i18n'

export default class LiveQuotesTicker {
  // eslint-disable-next-line no-undef
  constructor (params) {
    this.config = params
    this.containerElement = params.containerElement ? params.containerElement instanceof Element
    ? params.containerElement : document.querySelector(params.containerElement) : null
    this.packetId = 1
    this.config.instruments = []
    this.config.lang = Object.keys(i18n).includes(params.lang) ? params.lang : (i18n[document.documentElement.lang] || defaults.defaultLang)
  }

  get instruments () {
    return this.config.instruments
  }

  connectWS () {
    this.config.WS = new WSGate(this.config.wsgate)
    this.config.WS.connect()
  }

  disconnectWS () {
    this.config.WS.disconnect()
  }

  startListenWS() {
    this.config.WS.listen(this.WSlistenCallback.bind(this))
  }

  WSlistenCallback (data) {
    const quotes = utils.prepareQuotes(data)

    const data2paste = utils.getData2paste(quotes, this.config.instruments)

    utils.updateDigits({instruments: this.config.instruments, data2paste: data2paste, fillNodeCallback: this.config.fillNodeCallback, formatFunction: this.config.formatFunction})
  }

  proccessAllNodes () {
    return new Promise((r) => {
      const parentEl = this.containerElement ?? document
      // Collect all the present symbols
      const nodes = parentEl.querySelectorAll('[data-ticker-code]')

      // Add them to the list
      this.processNodes(nodes)
      // And animate all of them
      this.addInstruments(this.config.instruments.map(i => i.code))
      .then(() => {
        r( 1 )
      })
    })
  }

  observeMutations () {
    // accepts args: addedNodes (array)
    // fires when a node that satisfies selector is inserted in DOM tree
    let lastCallArgs = []
    const throttled = utils.throttle(this.addInstruments.bind(this), 100)

    const onAddAction = (addedNodes) => {
      this.processNodes(addedNodes)

      const newInstruments = this.config.instruments.filter(instrument => instrument.name === undefined).map(i => i.code)

      if (newInstruments.length && lastCallArgs.some((code) => !newInstruments.includes(code))) {
        throttled(newInstruments)
        lastCallArgs = newInstruments
      }
    }

    // accepts args: removedNodes (array)
    // fires when a node that satisfies selector is removed from DOM tree
    const onRemoveAction = (removedNodes) => {
      const removedInstruments = []
      ;[].forEach.call(removedNodes, el => {
        if (
          !removedInstruments.includes(el.dataset.tickerCode)
          && !document.querySelector(`[data-ticker-code="${el.dataset.tickerCode}"]`)
        ) removedInstruments.push( el.dataset.tickerCode )
      })

      if (removedInstruments.length) {

        this.removeInstrumets(removedInstruments)
      }
    }

    const observer = observeMutations(['[data-ticker-code]'], onAddAction, onRemoveAction, this.containerElement ?? document, {
      childList: true,
      subtree: true
    })
    this.observer = observer
  }


  stopObservingMutations () {
    this.observer.disconnect()
  }

  init () {
    // fix issue when clicking back navigation button
    // ticker stops working on the page
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.stop()
        this.start()
      }
    });

    return this.start()
  }

  start () {
    this.connectWS()

    return this.proccessAllNodes()
    .then(() => {
      this.startListenWS()
      this.observeMutations()
    })
  }

  stop () {
    this.disconnectWS()
    this.stopObservingMutations()
  }

  processNodes (nodeList) {
    ;[].forEach.call(nodeList, node => {
      const code = node.dataset.tickerCode
      let current = this.config.instruments.find(i => i.code.toLowerCase() === code.toLowerCase())

      if (!current) {
        current = {
          code: code,
          nodes: []
        }
        this.config.instruments.push(current)
      } else if (current.name !== undefined) {
        utils.fillNode({node: node, instrument: current, callback: this.config.fillNodeCallback, formatFunction: this.config.formatFunction})
      }

      if (!current.nodes.some(el => el === node)) current.nodes.push(node)
    })
  }

  prepareInstruments (symbols) {
    symbols.forEach(code => {
      let current = this.config.instruments.find(i => i.code.toLowerCase() === code.toLowerCase())

      if (!current) {
        current = {
          code: code,
          nodes: []
        }
        this.config.instruments.push(current)
      }
    })
  }

  addInstruments (symbols) {
    return api
      // Request the initial data and fix improper cases
      .getInitialNumbers({
        url: this.config.quotes,
        lang: this.config.lang,
        symbols: symbols
      })
      // Fix improper cases and fill up the table, subscribe to live updates
      .then(response => {
        if (this.config.quotesCallback) this.config.quotesCallback(symbols, response)

        utils.correctSymbolCases({instruments: this.config.instruments, data: response})
        utils.composeInitialData({instruments: this.config.instruments, data: response, fillNodeCallback: this.config.fillNodeCallback, formatFunction: this.config.formatFunction, getParameter: this.config.getParameter})
        utils.markUnresolved({instruments: this.config.instruments, symbols})
        this.config.WS.subscribe( this.getWSMessage('subscribe', symbols) )
      })
      .catch(error => {
        console.warn(error)
      })
  }

  removeInstrumets (symbols) {
    this.config.WS.unsubscribe( this.getWSMessage('unsubscribe', symbols) )
    this.config.instruments = this.config.instruments.filter(
      symbol => !symbols.map(s => s.toLowerCase()).includes(symbol.code.toLowerCase())
    )
  }

  getInstrument (code) {
    return this.config.instruments.find(i => i.code.toLowerCase() === code.toLowerCase())
  }

  listenWS (callback) {
    this.config.WS.listen(callback)
  }

  getWSMessage (route, symbols) {
    return {
      name: 'quotes.' + route,
      id: (this.packetId++).toString(),
      body: {
        stream: 'real',
        symbols
      }
    }
  }
}
