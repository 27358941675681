const getInitialNumbers = function ({url, lang, symbols}) {
  return window.fetch(`${url}/info/${lang}?intervals=D1&symbols=${symbols.join(',')}`)
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .then(response => {
      return response.data.instruments
    })
}

export default {
  getInitialNumbers
}
